@import "../../Autobopr/Assets/Functions.less";
@import (inline) "../../Autobopr/Assets/Utilities.css";
@CastleDetailsColour: #ff8400;
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1349px)";
@desktop: ~"(min-width: 1330px)";
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/";


.PTSansNarrow, .BodyFont {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 400;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700 !important;
}

.PatrickHand {
	font-family: 'Patrick Hand', cursive;
	font-weight: 400;
}

.container {
	position: relative;

	@media @mobile {
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		width: 1300px;
	}
}

html {
	font-size: 18px;
}

body {
	.BodyFont;
	background-color: #bff3ff;
	background-repeat: no-repeat;
	min-height: 100vh;
}

.textContent {
	font-size: 1rem;

	h1 {
		.PatrickHand;
		color: #0983dd;
		font-size: 2.2rem;
		margin-top: 0;
	}
}

.lower-content {
	margin-top:30px;
	h2 {
		.PatrickHand;
		color: #0983dd;
		font-size: 2.2rem;
		margin-top: 0;
	}

	&--homepage {
	}
}

.outer-wrapper {
	max-width: 100vw;
	overflow: hidden;

	&--homepage-has-booking {
		.columns__container {
			@media @sm {
				padding-top: 80px;
				margin-top: -45px;
			}
		}
	}
}

.top-wrapper {
	position: relative;
	background: radial-gradient(circle at center, #3bd1fe 0%, #007ad9 100%);

	&:before {
		.StickToEdges();
		background-repeat: repeat;
		background-size: 100% 100%;
		&:extend(.dot-tile);
	}
}

.header {
	@width: 1377px;
	@height: 321px;
	position: relative;

	@media @mobile {
		text-align: center;
		padding: 90px 0 20px;
	}

	@media @tablet {
		padding-top: percentage(round((@height/@width),2));
	}

	&__logo {
		.TopBarItem(425,@width,@height,auto,20,left,5);

		@media @mobile {
			display: block;
			margin: 0 auto;
			max-width: 80% !important;
		}
	}

	&__weather-post {
		.TopBarItem(185,@width,@height,auto,0,right,373);
	}

	&__weather-text {
		.TopBarItem(138,@width,@height,auto,128,right,384);
	}

	&__badges {
		@media @mobile {
			text-align: center;
			margin: 20px auto;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__tipe {
		.TopBarItem(64,@width,@height,auto,172,right,252);

		@media @mobile {
			margin: 0 8px;
			width: 80px;
		}
	}

	&__bcn {
		.TopBarItem(158,@width,@height,auto,178,right,81);

		@media @mobile {
			margin: 0 8px;
			width: 150px;
		}
	}

	&__biha {
		.TopBarItem(62,@width,@height,auto,174,right,5);

		@media @mobile {
			margin: 0 8px;
			width: 70px;
		}
	}

	&__contact {
		color: #ffea00;
		.PatrickHand;
		.TopBarItem(288,@width,@height,auto,125,right,23);
		line-height: 1;
		white-space: nowrap;
		font-size: 1.5rem;

		@media @mobile {
			display: block;
			margin: 20px auto 0;
		}

		@media @tablet {
			font-size: 2.8vw;
		}

		@media @desktop {
			font-size: 2.1rem;
		}
	}

	&__phone {
		.TopBarItem(288,@width,@height,auto,62,right,23);
		line-height: 1;
		white-space: nowrap;
		color: #fff;
		.PatrickHand;
		font-size: 2rem;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			display: block;
			margin: 10px auto 0;
		}

		@media @tablet {
			font-size: 3.9vw;
		}

		@media @desktop {
			font-size: 2.9rem;
		}
	}

	&__phone-2 {
		line-height: 1;
		white-space: nowrap;
		color: #fff;
		.PatrickHand;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			display: block;
			margin: 10px auto 0;
		}

		@media @tablet {
			position: absolute;
		}

		@media @desktop {
			font-size: 2.9rem;
		}
	}

	&__email {
		.PatrickHand;
		color: #fff;
		line-height: 1;
		white-space: nowrap;
		font-size: 1.4rem;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			display: block;
			margin: 10px auto 0;
		}

		@media @tablet {
			position: absolute;
			width: 24.32%;
			bottom: 7.17%;
			right: 0;
			font-size: 2.2vw;
		}

		@media @desktop {
			font-size: 1.7rem;
		}
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0% 0%;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #FFA800;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

.toplinks {
	display: none;

	@media @sm {
		display: block;
		//toplinks-bg.svg;
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1448 74' preserveAspectRatio='none'%3E%3Cdefs%3E%3Cstyle%3E.cls-1{fill:%23ffc10f;fill-rule:evenodd;}%3C/style%3E%3C/defs%3E%3Ctitle%3Etoplinks-bg%3C/title%3E%3Cpath class='cls-1' d='M0,0H1448l-23.64,35.84L1448,74H0L23.64,35.84Z'/%3E%3C/svg%3E");
		position: relative;
		background-size: 100% 100%;
		margin-left: -40px;
		margin-right: -40px;
		padding: 0 40px;

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			width: 100%;
			pointer-events: none;
			border-bottom: 1px dashed;
			z-index: 1;
		}

		&:before {
			top: 3px;
		}

		&:after {
			bottom: 3px;
		}

		&__nav {
			align-self: center;
		}

		&__ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 0;
			padding-left: 0;

			@media @md {
				justify-content: space-between;
			}
		}

		&__li {
			position: relative;
			list-style: none;
			text-align: center;
			flex-grow: 1;

			.dropdown-toggle:focus {
				text-decoration: none;
			}
		}

		&__link {
			position: relative;
			display: block;
			padding: 16px 10px;
			color: #fff;
			font-size: 1.3rem;
			transition: color 0.3s, background-color 0.3s;
			.PatrickHand;

			&:hover, &.active, &:focus {
				color: #fff;
				background-color: #f18700;
			}
		}

		&__dropdown {
			padding: 0;
			margin: 0;
			border: none;
			box-shadow: none;
			text-align: center;
			border: 1px solid #181818;
			background-color: #ffc10f !important;
			min-width: 100%;
		}

		&__dropdown-li {
			display: block;
		}

		&__dropdown-link {
			position: relative;
			padding: 5px 10px !important;
			font-size: 1.2rem;
			.PTSansNarrowBold;
			color: #fff !important;
			transition: background-color 0.3s;

			&:hover, &:focus, &.active {
				background-color: #f18700 !important;
				color: #fff !important;
			}
		}
	}
}

.banner {
	margin-top: 30px;
	padding-bottom: 85px;
	position: relative;

	@media (min-width: 948px) {
		display: flex;
		justify-content: space-between;
	}

	&__left {
		@media (min-width: 948px) {
			width: 70.68%;
		}
	}

	&__right {
		//		display: none;
		position: relative;

		@media (min-width: 948px) {
			width: 26.48%;
			padding-bottom: 85px;
		}
	}
}

.news-feed {
	margin-top: 50px;

	@media (min-width: 948px) {
		.StickToEdges;
		overflow: hidden;
		margin-top: 0;
	}

	&__heading {
		color: #fff;
		.PatrickHand;
		text-align: center;

		@media (min-width: 948px) {
			text-align: left;
		}
	}

	#NewsItems {
		@media (min-width:600px) {
			column-count: 2;
		}

		@media (min-width: 948px) {
			column-count: 1;
		}

		h4 {
			padding-top: 15px;
			border-top: 1px dashed #444444;
			color: #FFF
		}

		p {
			color: #FFF
		}
	}
}

.home-gallery {
	position: relative;
	margin-top: 50px;

	&__slides {
		padding: 0 30px;
	}

	&__slide {
		margin: 0 8px;

		.DetailsThumbnail.thumbnail {
			padding: 0 0 100%;
			margin: 0;
		}
	}

	&__button {
		display: block;
		position: absolute;
		width: 20px;
		height: 20px;
		background-size: 100% 100%;
		background-image: url("@{cloudUrl}home-gallery-arrow.png");
		background-color: transparent;
		border: none;

		&:hover {
		}

		&--prev {
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}

		&--next {
			right: 0;
			top: 50%;
			transform: translateY(-50%) scaleX(-1);
		}
	}
}

.book-online {
	position: relative;
	padding: 75px 0;
	background-image: url("@{cloudUrl}book-online-bg_1x.png");

	@media @retinaMedia {
		background-image: url("@{cloudUrl}book-online-bg_2x.png");
	}

	background-size: 100% 100%;
	margin-top: -35px;
	z-index: 1;

	&__inner {
		display: flex;
		justify-content: space-between;
	}

	&__graphic {
		width: 25.12%;
		position: relative;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-60%);
		}
	}

	&__widgets {
		width: 72.32%;
		display: flex;
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input, .SearchDate, .SearchCategory {
		width: 252px;
		height: 55px;
		border: none;
		border-radius: 5px;
		margin: 0 10px;
		font-size: 1rem;
		padding-left: 10px;

		@media @sm {
			width: 152px;
		}

		@media @md {
			width: 200px;
		}

		@media @desktop {
			width: 252px;
		}
	}

	#PostcodeSearchLeft select {
	}

	.SearchDate {
	}

	.SearchCategory {
	}

	.PostcodeMessage {
		bottom: -80px;
	}
}

.columns {
	@media @sm {
		display: flex;
		justify-content: space-between;
	}

	

	&__left {
		display: none;

		@media @sm {
			display: block;
			width: 28.64%;
		}
	}

	&__main {
		@media @sm {
			width: 69.44%;
		}
	}
}

.left-phone, .left-phone-2 {
	color: #0983dd;
	font-size: 1.6rem;
	text-align: center;
	margin-top: 20px;
	display: block;
	.PatrickHand;

	&:hover {
		color: #0983dd;
	}

	@media @sm {
		font-size: 2rem;
	}

	@media @md {
		font-size: 2.5rem;
	}

	@media @desktop {
		font-size: 3rem;
	}
}

.category-links {
	margin-bottom: 50px;

	&__link, &__child-link {
		.PTSansNarrowBold();
		padding: 15px;
		line-height: 32px;
		display: block;
		position: relative;
		margin: 10px 0;
		color: white;
		text-align: left;
		border-radius: 5px;
		text-shadow: 1px 1px 2px rgba(0,0,0,1);
		box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
		transition: padding 0.3s, box-shadow 0.3s;
		background-position: center bottom;
		font-size: 20px;
		z-index: 0;
		outline: 1px dashed #FFF;
		outline-offset: -5px;

		&:first-child {
			margin-top: 0;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			border-left: 8px solid #fff;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:nth-of-type(5n + 1) {
			.Gradient(#0ccdfd,#00a3eb);

			+ div .category-links__child-link {
				.Gradient(#0ccdfd,#00a3eb);
			}
		}

		&:nth-of-type(5n + 2) {
			.Gradient(#f04fad,#ea3584);

			+ div .category-links__child-link {
				.Gradient(#f04fad,#ea3584);
			}
		}

		&:nth-of-type(5n + 3) {
			.Gradient(#ae49ac,#812e7f);

			+ div .category-links__child-link {
				.Gradient(#ae49ac,#812e7f);
			}
		}

		&:nth-of-type(5n + 4) {
			.Gradient(#21bbb4,#17998f);

			+ div .category-links__child-link {
				.Gradient(#21bbb4,#17998f);
			}
		}

		&:nth-of-type(5n + 5) {
			.Gradient(#f2d302,#eab800);

			+ div .category-links__child-link {
				.Gradient(#f2d302,#eab800);
			}
		}

		&:hover, &:focus, &.active {
			box-shadow: 0 0 0 0 rgba(0,0,0,0);
			outline: 1px dashed #FFF;
			color: white;
			padding-left: 20px;

			&:before {
				opacity: 1;
			}
		}
	}

	&__child-link {
		margin-left: 10px;
	}
}

.testimonials {
	&__outer {
		position: relative;
		z-index: 1;
		margin: 20px 0;

		&:before, &:after {
			content: "";
			background-image: url("@{cloudUrl}testimonials-wave.png");
			height: 8px;
			background-size: auto 100%;
			position: absolute;
			width: 150vw;
			left: 50%;
			z-index: -1;
		}

		&:before {
			top: 0;
			transform: translateX(-75vw)translateY(-8px);
		}

		&:after {
			bottom: 0;
			transform: translateX(-75vw) translateY(8px) scaleY(-1);
		}
	}

	&__inner {
		position: relative;
		z-index: 1;
		padding: 40px 20px;

		@media @sm {
			padding: 20px;
		}

		&:before {
			content: "";
			background-color: #36bcb3;
			position: absolute;
			top: 0;
			bottom: 0;
			width: 150vw;
			left: 50%;
			transform: translateX(-75vw);
			z-index: -1;
		}
	}

	&__col {
		@media (max-width:550px) {
			width: 100%;

			&:first-child {
				margin-bottom: 60px;
			}
		}
	}

	&__testimonial-panel {
		background: #fff;
		border-radius: 10px;
		position: relative;
		padding: 40px;

		&:before {
			.StickToEdges();
			background-repeat: repeat;
			background-size: 100% 100%;
			&:extend(.dot-tile);
		}
	}

	&__testimonial-link {
		color: #494949;
		text-align: center;
		font-size: 1rem;
		.PatrickHand;
		display: flex;
		flex-direction: column-reverse;
	}

	&__quote {
		position: absolute;
		width: 33px;
		height: 28px;
		left: 50%;
		background-image: url("@{cloudUrl}quote.png");
		background-size: 100% 100%;

		&--top {
			top: 0;
			transform: translate3d(-50%,-50%,0);
		}

		&--bottom {
			bottom: 0;
			transform: translate3d(-50%,50%,0);
		}
	}
}

.delivery {
	background-image: url("@{cloudUrl}delivery-bg.png");
	border-radius: 5px;
	padding: 10px 20px 50px;
	margin-top: 70px;
	position: relative;
	.PatrickHand;
	color: #fff;
	font-size: 1rem;
	text-align: center;

	@media @sm {
		padding: 10px 88px 50px;
	}

	&__contact {
		background: #00aeef;
		height: 50px;
		display: flex;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate3d(-50%,10px,0);
		.border-top-radius(10px);
		.FlexCenter;
		color: #fff;
		box-shadow: 0 -4px 0 rgba(0,0,0,0.3);
		padding: 0 20px;
		font-size: 1.1rem;
		transition: color 0.3s;

		&:hover {
			color: #ffee00;
		}

		&:before, &:after {
			content: "";
			position: absolute;
			bottom: 0;
		}

		&:before {
			border-top: 5px solid #028ad1;
			border-right: 5px solid #028ad1;
			border-bottom: 5px solid transparent;
			border-left: 5px solid transparent;
			left: 0;
			transform: translateX(-10px);
		}

		&:after {
			border-top: 5px solid #028ad1;
			border-left: 5px solid #028ad1;
			border-bottom: 5px solid transparent;
			border-right: 5px solid transparent;
			right: 0;
			transform: translateX(9px);
		}
	}
}

.newsletter {
	background-color: #36bcb3;
	margin-top: 70px;
	background-image: url("@{cloudUrl}testimonials-bg.png");
	border-radius: 5px;
	padding: 10px 15px;
	color: #000;
	font-size: 1rem;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	@media @sm {
		padding: 10px 44px;
	}

	@media @md {
		display: flex;
		justify-content: space-between;
	}

	@media @desktop {
	}

	&__graphic {
		margin-right: 10px;
		margin-bottom: 20px;

		@media @md {
			max-width: 43%;
			margin-bottom: 0;
		}
	}

	&__control {
		display: flex;
		max-width: 100%;
		align-items: center;
		margin: 10px auto 25px;
		text-align: center;

		@media (max-width:595px) {
			margin: 10px auto 0;
		}

		@media @md {
			margin: 0 auto;
		}
	}

	p, .clear {
		display: none;
	}

	&__name, &__email {
		height: 55px;
		border: none;
		border-radius: 5px;
		margin: 0 10px 10px;
		padding-left: 10px;
		display: block;
		width: 250px;

		@media (min-width:595px) {
			width: 450px;
			max-width: 70%;
			margin: 0 auto 10px;
		}

		@media (min-width:560px) {
			width: 190px;
			display: inline;
			margin: 0 10px;
		}

		@media @sm {
			width: 252px;
		}

		@media @md {
			width: 190px;
		}

		@media @desktop {
			width: 252px;
		}
	}

	&__name {
	}

	&__email {
	}

	&__button {
		font-size: 0;
		width: 54px;
		height: 55px;
		display: inline-block;
		line-height: 55px;
		background-image: url("@{cloudUrl}newsletter-submit-bg.png");
		background-size: 55px 35px;
		background-position: 40% 50%;
		background-repeat: no-repeat;
	}
}

.products-intro, .testimonials-intro {
	color: #fff;
	position: relative;
	.PatrickHand;
	font-size: 2rem;
	line-height: 1;
	text-align: center;
	z-index: 1;
	padding: 15px 15px 25px;
	margin-bottom: 40px;

	&:before {
		content: "";
		background-image: url("@{cloudUrl}products-intro-bg.png");
		background-size: auto 100%;
		background-repeat: repeat-x;
		position: absolute;
		top: 0;
		bottom: 0;
		width: 150vw;
		left: 50%;
		transform: translateX(-75vw);
		z-index: -1;
	}
}

.testimonials-intro {
	margin-bottom: 0;

	&:before {
		background-image: url("@{cloudUrl}testimonials-intro-bg.png");
	}
}

.page-listings {
	padding-left: 10px;
	padding-right: 20px;

	& > div {
		@media (max-width:420px) {
			width: 100%;
		}

		&:nth-child(8n + 1) .castlePanel, &:nth-child(8n + 6) .castlePanel {
			.box-shadow(10px 10px 0 0 #00adff);
			background-color: #6ccefd;
		}

		&:nth-child(8n + 2) .castlePanel, &:nth-child(8n + 8) .castlePanel {
			.box-shadow(10px 10px 0 0 #2cc038);
			background-color: #5ee565;
		}

		&:nth-child(8n + 3) .castlePanel, &:nth-child(8n + 5) .castlePanel {
			.box-shadow(10px 10px 0 0 #e55555);
			background-color: #fca2a2;
		}

		&:nth-child(8n + 4) .castlePanel, &:nth-child(8n + 7) .castlePanel {
			.box-shadow(10px 10px 0 0 #dba43d );
			background-color: #fec868;
		}
	}
}

.castlePanel {
	display: grid;
	grid-template-columns: 1fr 1fr;

	.SingleImagePanel, .castleTitlePanel {
		grid-column: span 2;
	}

	.castlePriceDefault, .castlePriceDiscounted {
		grid-row-start: 3;
		grid-column-start: 2;
		grid-column: span 1;
	}

	.castleCheckBook {
		grid-column: span 1;
		grid-column-start: 1;
		grid-row-start: 3;
		.FlexCenter;
		padding: 0 0 0 15px;
	}
}

.castlePanel {
	width: 100%;
	margin-bottom: 20px;
	border: none;
	overflow: hidden;
	padding: 10px;
	border-radius: 20px;

	&:hover {
	}

	.SingleImagePanel {
		height: auto;
		background-color: transparent;
		padding-top: 0;
		overflow: hidden;

		&:before {
			content: "";
			display: block;
			padding-top: 100%;
			width: 100%;
		}

		img {
			@supports

			(mix-blend-mode:multiply) {
				width: 100%;
				height: 100%;
				top: 0 !important;
				left: 0 !important;
				object-fit: cover;
			}
		}
	}

	.castleTitlePanel {
		margin: 10px auto;
		display: flex;
		align-items: center;

		a {
			.PTSansNarrowBold;
			font-size: 1.2rem;
			color: #084ab1;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		height: auto;
		font-size: 1.5rem;
		color: #404040;
		.FlexCenter;
		//.MontserratBold;
		//.OpenSansBold;
		.border-bottom-radius(20px);

		span.oldprice {
			font-size: 0.65rem;
		}

		span.newprice {
			font-size: 0.8rem;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		height: auto;
		padding: 10px 5px;
		text-transform: uppercase;
		border-radius: 25px;
		background: #2ab8fc;
		color: #fff;
		border-radius: 15px;
		border: 2px solid #fff;
		.PatrickHand;
		font-size: 1.033rem;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
	}
}

.columns__container {
	padding-bottom:100px;
	@media @sm {
		padding-bottom:200px;
		padding-top: 20px;
	}
	
}

.footer {
	position: relative;
	padding-top:15px;
	padding-bottom:15px;

	@media @mobile {
		text-align: center;
	}

	@media @desktop {
	}

	&__bg {
		max-width: none !important;
		width: 50%;
		position:absolute;
		bottom:0;

		&--left {
			left:0;
		}

		&--right {
			transform: scaleX(-1);
			right:0;
		}
	}

	&__container {
		
	}

	&__col {
		@media @mobile {
			margin-bottom: 15px;
		}
	}

	&__col--right {
		@media @md {
			text-align: right;
		}
	}

	&__copy {
		font-size: 1rem;
		background: rgba(255,255,255,0.5);
		border-radius: 5px;
		padding: 0 5px;
	}

	&__bcn {
		max-width: 180px;
	}

	&__social-links {
		display: flex;
		justify-content: center;
	}

	&__social-link {
		margin: 0 5px;
		width: 45px;
	}

	&__links {
		margin-top: 30px;
		text-align: center;
		font-size: 1rem;

		@media @mobile {
			padding: 10px;
			border-radius: 10px;
			background: rgba(0,0,0,0.3);
		}
	}

	&__link {
		display: inline-block;
		margin: 0 5px;
		color: #fff;

		@media @mobile {
			padding: 10px 0;
		}

		&:hover {
			color: #fff;
		}
	}

	&__bcn {
		display: inline-block;
	}
}

.DetailsTitle {
	h1, h2 {
		background-color: @CastleDetailsColour;
	}
}

.DetailsMainImage {
	background-color: @CastleDetailsColour;
}

.dot-tile {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAyBAMAAAAU8W51AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURQAAAAgICAQEBAICAgYGButKD8YAAAAFdFJOUwAKBgQHU1PKwAAAAHxJREFUOMvdlEsKwCAMREO9gAYPUIz3P2NJhEIhmS6CG2f3mICYzxDR1ZaYVF/6c8s07OLQW84u5dwyZutDXFr1HJLWS0iHScC3b2LQskpRg5UqRcNRyrruQhmp6y6jUdbF7+76L+4znhGe76HC94tuH+fGtrzCOZlJYOA+ZrsiUfKf8EEAAAAASUVORK5CYII=') /*dot-tile-background.png*/;
	background-size: 25px 25px;
	background-attachment: fixed;
}


@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}
