/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

.PTSansNarrow,
.BodyFont {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
}
.PatrickHand {
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
}
.container {
  position: relative;
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}
html {
  font-size: 18px;
}
body {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 400;
  background-color: #bff3ff;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.textContent {
  font-size: 1rem;
}
.textContent h1 {
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  color: #0983dd;
  font-size: 2.2rem;
  margin-top: 0;
}
.lower-content {
  margin-top: 30px;
}
.lower-content h2 {
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  color: #0983dd;
  font-size: 2.2rem;
  margin-top: 0;
}
.outer-wrapper {
  max-width: 100vw;
  overflow: hidden;
}
@media (min-width: 768px) {
  .outer-wrapper--homepage-has-booking .columns__container {
    padding-top: 80px;
    margin-top: -45px;
  }
}
.top-wrapper {
  position: relative;
  background: radial-gradient(circle at center, #3bd1fe 0%, #007ad9 100%);
}
.top-wrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-size: 100% 100%;
}
.header {
  position: relative;
}
@media (max-width: 767px) {
  .header {
    text-align: center;
    padding: 90px 0 20px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 23%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 30.86%;
    bottom: 6.23%;
    left: 0.36%;
  }
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 0 auto;
    max-width: 80% !important;
  }
}
@media (max-width: 767px) {
  .header__weather-post {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__weather-post {
    position: absolute;
    width: 13.44%;
    bottom: 0%;
    right: 27.09%;
  }
}
@media (max-width: 767px) {
  .header__weather-text {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__weather-text {
    position: absolute;
    width: 10.02%;
    bottom: 39.88%;
    right: 27.89%;
  }
}
@media (max-width: 767px) {
  .header__badges {
    text-align: center;
    margin: 20px auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__tipe {
    position: absolute;
    width: 4.65%;
    bottom: 53.58%;
    right: 18.3%;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    margin: 0 8px;
    width: 80px;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__bcn {
    position: absolute;
    width: 11.47%;
    bottom: 55.45%;
    right: 5.88%;
  }
}
@media (max-width: 767px) {
  .header__bcn {
    margin: 0 8px;
    width: 150px;
  }
}
@media (max-width: 767px) {
  .header__biha {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__biha {
    position: absolute;
    width: 4.5%;
    bottom: 54.21%;
    right: 0.36%;
  }
}
@media (max-width: 767px) {
  .header__biha {
    margin: 0 8px;
    width: 70px;
  }
}
.header__contact {
  color: #ffea00;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  font-size: 1.5rem;
}
@media (max-width: 767px) {
  .header__contact {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__contact {
    position: absolute;
    width: 20.92%;
    bottom: 38.94%;
    right: 1.67%;
  }
}
@media (max-width: 767px) {
  .header__contact {
    display: block;
    margin: 20px auto 0;
  }
}
@media (min-width: 768px) {
  .header__contact {
    font-size: 2.8vw;
  }
}
@media (min-width: 1330px) {
  .header__contact {
    font-size: 2.1rem;
  }
}
.header__phone {
  line-height: 1;
  white-space: nowrap;
  color: #fff;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  font-size: 2rem;
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 20.92%;
    bottom: 19.31%;
    right: 1.67%;
  }
}
.header__phone:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__phone {
    display: block;
    margin: 10px auto 0;
  }
}
@media (min-width: 768px) {
  .header__phone {
    font-size: 3.9vw;
  }
}
@media (min-width: 1330px) {
  .header__phone {
    font-size: 2.9rem;
  }
}
.header__phone-2 {
  line-height: 1;
  white-space: nowrap;
  color: #fff;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
}
.header__phone-2:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__phone-2 {
    display: block;
    margin: 10px auto 0;
  }
}
@media (min-width: 768px) {
  .header__phone-2 {
    position: absolute;
  }
}
@media (min-width: 1330px) {
  .header__phone-2 {
    font-size: 2.9rem;
  }
}
.header__email {
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  white-space: nowrap;
  font-size: 1.4rem;
}
.header__email:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .header__email {
    display: block;
    margin: 10px auto 0;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    width: 24.32%;
    bottom: 7.17%;
    right: 0;
    font-size: 2.2vw;
  }
}
@media (min-width: 1330px) {
  .header__email {
    font-size: 1.7rem;
  }
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0% 0%;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #FFA800;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
.toplinks {
  display: none;
}
@media (min-width: 768px) {
  .toplinks {
    display: block;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1448 74' preserveAspectRatio='none'%3E%3Cdefs%3E%3Cstyle%3E.cls-1{fill:%23ffc10f;fill-rule:evenodd;}%3C/style%3E%3C/defs%3E%3Ctitle%3Etoplinks-bg%3C/title%3E%3Cpath class='cls-1' d='M0,0H1448l-23.64,35.84L1448,74H0L23.64,35.84Z'/%3E%3C/svg%3E");
    position: relative;
    background-size: 100% 100%;
    margin-left: -40px;
    margin-right: -40px;
    padding: 0 40px;
  }
  .toplinks:before,
  .toplinks:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    pointer-events: none;
    border-bottom: 1px dashed;
    z-index: 1;
  }
  .toplinks:before {
    top: 3px;
  }
  .toplinks:after {
    bottom: 3px;
  }
  .toplinks__nav {
    -ms-flex-item-align: center;
        align-self: center;
  }
  .toplinks__ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;
  }
  .toplinks__li {
    position: relative;
    list-style: none;
    text-align: center;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .toplinks__li .dropdown-toggle:focus {
    text-decoration: none;
  }
  .toplinks__link {
    position: relative;
    display: block;
    padding: 16px 10px;
    color: #fff;
    font-size: 1.3rem;
    -webkit-transition: color 0.3s, background-color 0.3s;
    transition: color 0.3s, background-color 0.3s;
    font-family: 'Patrick Hand', cursive;
    font-weight: 400;
  }
  .toplinks__link:hover,
  .toplinks__link.active,
  .toplinks__link:focus {
    color: #fff;
    background-color: #f18700;
  }
  .toplinks__dropdown {
    padding: 0;
    margin: 0;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    text-align: center;
    border: 1px solid #181818;
    background-color: #ffc10f !important;
    min-width: 100%;
  }
  .toplinks__dropdown-li {
    display: block;
  }
  .toplinks__dropdown-link {
    position: relative;
    padding: 5px 10px !important;
    font-size: 1.2rem;
    font-family: 'PT Sans Narrow', sans-serif;
    font-weight: 700 !important;
    color: #fff !important;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }
  .toplinks__dropdown-link:hover,
  .toplinks__dropdown-link:focus,
  .toplinks__dropdown-link.active {
    background-color: #f18700 !important;
    color: #fff !important;
  }
}
@media (min-width: 768px) and (min-width: 992px) {
  .toplinks__ul {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.banner {
  margin-top: 30px;
  padding-bottom: 85px;
  position: relative;
}
@media (min-width: 948px) {
  .banner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
@media (min-width: 948px) {
  .banner__left {
    width: 70.68%;
  }
}
.banner__right {
  position: relative;
}
@media (min-width: 948px) {
  .banner__right {
    width: 26.48%;
    padding-bottom: 85px;
  }
}
.news-feed {
  margin-top: 50px;
}
@media (min-width: 948px) {
  .news-feed {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    margin-top: 0;
  }
}
.news-feed__heading {
  color: #fff;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  text-align: center;
}
@media (min-width: 948px) {
  .news-feed__heading {
    text-align: left;
  }
}
@media (min-width: 600px) {
  .news-feed #NewsItems {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
  }
}
@media (min-width: 948px) {
  .news-feed #NewsItems {
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }
}
.news-feed #NewsItems h4 {
  padding-top: 15px;
  border-top: 1px dashed #444444;
  color: #FFF;
}
.news-feed #NewsItems p {
  color: #FFF;
}
.home-gallery {
  position: relative;
  margin-top: 50px;
}
.home-gallery__slides {
  padding: 0 30px;
}
.home-gallery__slide {
  margin: 0 8px;
}
.home-gallery__slide .DetailsThumbnail.thumbnail {
  padding: 0 0 100%;
  margin: 0;
}
.home-gallery__button {
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/home-gallery-arrow.png");
  background-color: transparent;
  border: none;
}
.home-gallery__button--prev {
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.home-gallery__button--next {
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) scaleX(-1);
          transform: translateY(-50%) scaleX(-1);
}
.book-online {
  position: relative;
  padding: 75px 0;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/book-online-bg_1x.png");
  background-size: 100% 100%;
  margin-top: -35px;
  z-index: 1;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .book-online {
    background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/book-online-bg_2x.png");
  }
}
.book-online__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.book-online__graphic {
  width: 25.12%;
  position: relative;
}
.book-online__graphic img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-60%);
          transform: translateX(-50%) translateY(-60%);
}
.book-online__widgets {
  width: 72.32%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.book-online #PostcodeSearchLeft select,
.book-online #PostcodeSearchLeft input,
.book-online .SearchDate,
.book-online .SearchCategory {
  width: 252px;
  height: 55px;
  border: none;
  border-radius: 5px;
  margin: 0 10px;
  font-size: 1rem;
  padding-left: 10px;
}
@media (min-width: 768px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    width: 152px;
  }
}
@media (min-width: 992px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    width: 200px;
  }
}
@media (min-width: 1330px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input,
  .book-online .SearchDate,
  .book-online .SearchCategory {
    width: 252px;
  }
}
.book-online .PostcodeMessage {
  bottom: -80px;
}
@media (min-width: 768px) {
  .columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.columns__left {
  display: none;
}
@media (min-width: 768px) {
  .columns__left {
    display: block;
    width: 28.64%;
  }
}
@media (min-width: 768px) {
  .columns__main {
    width: 69.44%;
  }
}
.left-phone,
.left-phone-2 {
  color: #0983dd;
  font-size: 1.6rem;
  text-align: center;
  margin-top: 20px;
  display: block;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
}
.left-phone:hover,
.left-phone-2:hover {
  color: #0983dd;
}
@media (min-width: 768px) {
  .left-phone,
  .left-phone-2 {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .left-phone,
  .left-phone-2 {
    font-size: 2.5rem;
  }
}
@media (min-width: 1330px) {
  .left-phone,
  .left-phone-2 {
    font-size: 3rem;
  }
}
.category-links {
  margin-bottom: 50px;
}
.category-links__link,
.category-links__child-link {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  padding: 15px;
  line-height: 32px;
  display: block;
  position: relative;
  margin: 10px 0;
  color: white;
  text-align: left;
  border-radius: 5px;
  text-shadow: 1px 1px 2px #000000;
  -webkit-box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: padding 0.3s, -webkit-box-shadow 0.3s;
  transition: padding 0.3s, -webkit-box-shadow 0.3s;
  transition: padding 0.3s, box-shadow 0.3s;
  transition: padding 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  background-position: center bottom;
  font-size: 20px;
  z-index: 0;
  outline: 1px dashed #FFF;
  outline-offset: -5px;
}
.category-links__link:first-child,
.category-links__child-link:first-child {
  margin-top: 0;
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.category-links__link:nth-of-type(5n + 1),
.category-links__child-link:nth-of-type(5n + 1) {
  background-color: #0ccdfd;
  background: -webkit-gradient(linear, left top, left bottom, from(#0ccdfd), to(#00a3eb));
  background: linear-gradient(to bottom, #0ccdfd, #00a3eb);
}
.category-links__link:nth-of-type(5n + 1) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 1) + div .category-links__child-link {
  background-color: #0ccdfd;
  background: -webkit-gradient(linear, left top, left bottom, from(#0ccdfd), to(#00a3eb));
  background: linear-gradient(to bottom, #0ccdfd, #00a3eb);
}
.category-links__link:nth-of-type(5n + 2),
.category-links__child-link:nth-of-type(5n + 2) {
  background-color: #f04fad;
  background: -webkit-gradient(linear, left top, left bottom, from(#f04fad), to(#ea3584));
  background: linear-gradient(to bottom, #f04fad, #ea3584);
}
.category-links__link:nth-of-type(5n + 2) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 2) + div .category-links__child-link {
  background-color: #f04fad;
  background: -webkit-gradient(linear, left top, left bottom, from(#f04fad), to(#ea3584));
  background: linear-gradient(to bottom, #f04fad, #ea3584);
}
.category-links__link:nth-of-type(5n + 3),
.category-links__child-link:nth-of-type(5n + 3) {
  background-color: #ae49ac;
  background: -webkit-gradient(linear, left top, left bottom, from(#ae49ac), to(#812e7f));
  background: linear-gradient(to bottom, #ae49ac, #812e7f);
}
.category-links__link:nth-of-type(5n + 3) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 3) + div .category-links__child-link {
  background-color: #ae49ac;
  background: -webkit-gradient(linear, left top, left bottom, from(#ae49ac), to(#812e7f));
  background: linear-gradient(to bottom, #ae49ac, #812e7f);
}
.category-links__link:nth-of-type(5n + 4),
.category-links__child-link:nth-of-type(5n + 4) {
  background-color: #21bbb4;
  background: -webkit-gradient(linear, left top, left bottom, from(#21bbb4), to(#17998f));
  background: linear-gradient(to bottom, #21bbb4, #17998f);
}
.category-links__link:nth-of-type(5n + 4) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 4) + div .category-links__child-link {
  background-color: #21bbb4;
  background: -webkit-gradient(linear, left top, left bottom, from(#21bbb4), to(#17998f));
  background: linear-gradient(to bottom, #21bbb4, #17998f);
}
.category-links__link:nth-of-type(5n + 5),
.category-links__child-link:nth-of-type(5n + 5) {
  background-color: #f2d302;
  background: -webkit-gradient(linear, left top, left bottom, from(#f2d302), to(#eab800));
  background: linear-gradient(to bottom, #f2d302, #eab800);
}
.category-links__link:nth-of-type(5n + 5) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 5) + div .category-links__child-link {
  background-color: #f2d302;
  background: -webkit-gradient(linear, left top, left bottom, from(#f2d302), to(#eab800));
  background: linear-gradient(to bottom, #f2d302, #eab800);
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  outline: 1px dashed #FFF;
  color: white;
  padding-left: 20px;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
.testimonials__outer {
  position: relative;
  z-index: 1;
  margin: 20px 0;
}
.testimonials__outer:before,
.testimonials__outer:after {
  content: "";
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/testimonials-wave.png");
  height: 8px;
  background-size: auto 100%;
  position: absolute;
  width: 150vw;
  left: 50%;
  z-index: -1;
}
.testimonials__outer:before {
  top: 0;
  -webkit-transform: translateX(-75vw) translateY(-8px);
          transform: translateX(-75vw) translateY(-8px);
}
.testimonials__outer:after {
  bottom: 0;
  -webkit-transform: translateX(-75vw) translateY(8px) scaleY(-1);
          transform: translateX(-75vw) translateY(8px) scaleY(-1);
}
.testimonials__inner {
  position: relative;
  z-index: 1;
  padding: 40px 20px;
}
@media (min-width: 768px) {
  .testimonials__inner {
    padding: 20px;
  }
}
.testimonials__inner:before {
  content: "";
  background-color: #36bcb3;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 150vw;
  left: 50%;
  -webkit-transform: translateX(-75vw);
          transform: translateX(-75vw);
  z-index: -1;
}
@media (max-width: 550px) {
  .testimonials__col {
    width: 100%;
  }
  .testimonials__col:first-child {
    margin-bottom: 60px;
  }
}
.testimonials__testimonial-panel {
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 40px;
}
.testimonials__testimonial-panel:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: repeat;
  background-size: 100% 100%;
}
.testimonials__testimonial-link {
  color: #494949;
  text-align: center;
  font-size: 1rem;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}
.testimonials__quote {
  position: absolute;
  width: 33px;
  height: 28px;
  left: 50%;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/quote.png");
  background-size: 100% 100%;
}
.testimonials__quote--top {
  top: 0;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}
.testimonials__quote--bottom {
  bottom: 0;
  -webkit-transform: translate3d(-50%, 50%, 0);
          transform: translate3d(-50%, 50%, 0);
}
.delivery {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/delivery-bg.png");
  border-radius: 5px;
  padding: 10px 20px 50px;
  margin-top: 70px;
  position: relative;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .delivery {
    padding: 10px 88px 50px;
  }
}
.delivery__contact {
  background: #00aeef;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, 10px, 0);
          transform: translate3d(-50%, 10px, 0);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  -webkit-box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 -4px 0 rgba(0, 0, 0, 0.3);
  padding: 0 20px;
  font-size: 1.1rem;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
.delivery__contact:hover {
  color: #ffee00;
}
.delivery__contact:before,
.delivery__contact:after {
  content: "";
  position: absolute;
  bottom: 0;
}
.delivery__contact:before {
  border-top: 5px solid #028ad1;
  border-right: 5px solid #028ad1;
  border-bottom: 5px solid transparent;
  border-left: 5px solid transparent;
  left: 0;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}
.delivery__contact:after {
  border-top: 5px solid #028ad1;
  border-left: 5px solid #028ad1;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  right: 0;
  -webkit-transform: translateX(9px);
          transform: translateX(9px);
}
.newsletter {
  background-color: #36bcb3;
  margin-top: 70px;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/testimonials-bg.png");
  border-radius: 5px;
  padding: 10px 15px;
  color: #000;
  font-size: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 768px) {
  .newsletter {
    padding: 10px 44px;
  }
}
@media (min-width: 992px) {
  .newsletter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.newsletter__graphic {
  margin-right: 10px;
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .newsletter__graphic {
    max-width: 43%;
    margin-bottom: 0;
  }
}
.newsletter__control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px auto 25px;
  text-align: center;
}
@media (max-width: 595px) {
  .newsletter__control {
    margin: 10px auto 0;
  }
}
@media (min-width: 992px) {
  .newsletter__control {
    margin: 0 auto;
  }
}
.newsletter p,
.newsletter .clear {
  display: none;
}
.newsletter__name,
.newsletter__email {
  height: 55px;
  border: none;
  border-radius: 5px;
  margin: 0 10px 10px;
  padding-left: 10px;
  display: block;
  width: 250px;
}
@media (min-width: 595px) {
  .newsletter__name,
  .newsletter__email {
    width: 450px;
    max-width: 70%;
    margin: 0 auto 10px;
  }
}
@media (min-width: 560px) {
  .newsletter__name,
  .newsletter__email {
    width: 190px;
    display: inline;
    margin: 0 10px;
  }
}
@media (min-width: 768px) {
  .newsletter__name,
  .newsletter__email {
    width: 252px;
  }
}
@media (min-width: 992px) {
  .newsletter__name,
  .newsletter__email {
    width: 190px;
  }
}
@media (min-width: 1330px) {
  .newsletter__name,
  .newsletter__email {
    width: 252px;
  }
}
.newsletter__button {
  font-size: 0;
  width: 54px;
  height: 55px;
  display: inline-block;
  line-height: 55px;
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/newsletter-submit-bg.png");
  background-size: 55px 35px;
  background-position: 40% 50%;
  background-repeat: no-repeat;
}
.products-intro,
.testimonials-intro {
  color: #fff;
  position: relative;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  z-index: 1;
  padding: 15px 15px 25px;
  margin-bottom: 40px;
}
.products-intro:before,
.testimonials-intro:before {
  content: "";
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/products-intro-bg.png");
  background-size: auto 100%;
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 150vw;
  left: 50%;
  -webkit-transform: translateX(-75vw);
          transform: translateX(-75vw);
  z-index: -1;
}
.testimonials-intro {
  margin-bottom: 0;
}
.testimonials-intro:before {
  background-image: url("https://files.bookingonline.co.uk/image/upload/f_auto/themes/024/testimonials-intro-bg.png");
}
.page-listings {
  padding-left: 10px;
  padding-right: 20px;
}
@media (max-width: 420px) {
  .page-listings > div {
    width: 100%;
  }
}
.page-listings > div:nth-child(8n + 1) .castlePanel,
.page-listings > div:nth-child(8n + 6) .castlePanel {
  -webkit-box-shadow: 10px 10px 0 0 #00adff;
  box-shadow: 10px 10px 0 0 #00adff;
  background-color: #6ccefd;
}
.page-listings > div:nth-child(8n + 2) .castlePanel,
.page-listings > div:nth-child(8n + 8) .castlePanel {
  -webkit-box-shadow: 10px 10px 0 0 #2cc038;
  box-shadow: 10px 10px 0 0 #2cc038;
  background-color: #5ee565;
}
.page-listings > div:nth-child(8n + 3) .castlePanel,
.page-listings > div:nth-child(8n + 5) .castlePanel {
  -webkit-box-shadow: 10px 10px 0 0 #e55555;
  box-shadow: 10px 10px 0 0 #e55555;
  background-color: #fca2a2;
}
.page-listings > div:nth-child(8n + 4) .castlePanel,
.page-listings > div:nth-child(8n + 7) .castlePanel {
  -webkit-box-shadow: 10px 10px 0 0 #dba43d;
  box-shadow: 10px 10px 0 0 #dba43d;
  background-color: #fec868;
}
.castlePanel {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.castlePanel .SingleImagePanel,
.castlePanel .castleTitlePanel {
  grid-column: span 2;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  grid-row-start: 3;
  grid-column-start: 2;
  grid-column: span 1;
}
.castlePanel .castleCheckBook {
  grid-column: span 1;
  grid-column-start: 1;
  grid-row-start: 3;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 0 0 15px;
}
.castlePanel {
  width: 100%;
  margin-bottom: 20px;
  border: none;
  overflow: hidden;
  padding: 10px;
  border-radius: 20px;
}
.castlePanel .SingleImagePanel {
  height: auto;
  background-color: transparent;
  padding-top: 0;
  overflow: hidden;
}
.castlePanel .SingleImagePanel:before {
  content: "";
  display: block;
  padding-top: 100%;
  width: 100%;
}
@supports (mix-blend-mode:multiply) {
  .castlePanel .SingleImagePanel img {
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.castlePanel .castleTitlePanel {
  margin: 10px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleTitlePanel a {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700 !important;
  font-size: 1.2rem;
  color: #084ab1;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  height: auto;
  font-size: 1.5rem;
  color: #404040;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.65rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 0.8rem;
}
.castlePanel .castleCheckBook {
  height: auto;
  padding: 10px 5px;
  text-transform: uppercase;
  border-radius: 25px;
  background: #2ab8fc;
  color: #fff;
  border-radius: 15px;
  border: 2px solid #fff;
  font-family: 'Patrick Hand', cursive;
  font-weight: 400;
  font-size: 1.033rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.columns__container {
  padding-bottom: 100px;
}
@media (min-width: 768px) {
  .columns__container {
    padding-bottom: 200px;
    padding-top: 20px;
  }
}
.footer {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
.footer__bg {
  max-width: none !important;
  width: 50%;
  position: absolute;
  bottom: 0;
}
.footer__bg--left {
  left: 0;
}
.footer__bg--right {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  right: 0;
}
@media (max-width: 767px) {
  .footer__col {
    margin-bottom: 15px;
  }
}
@media (min-width: 992px) {
  .footer__col--right {
    text-align: right;
  }
}
.footer__copy {
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 0 5px;
}
.footer__bcn {
  max-width: 180px;
}
.footer__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.footer__social-link {
  margin: 0 5px;
  width: 45px;
}
.footer__links {
  margin-top: 30px;
  text-align: center;
  font-size: 1rem;
}
@media (max-width: 767px) {
  .footer__links {
    padding: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);
  }
}
.footer__link {
  display: inline-block;
  margin: 0 5px;
  color: #fff;
}
@media (max-width: 767px) {
  .footer__link {
    padding: 10px 0;
  }
}
.footer__link:hover {
  color: #fff;
}
.footer__bcn {
  display: inline-block;
}
.DetailsTitle h1,
.DetailsTitle h2 {
  background-color: #ff8400;
}
.DetailsMainImage {
  background-color: #ff8400;
}
.dot-tile,
.top-wrapper:before,
.testimonials__testimonial-panel:before {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADkAAAAyBAMAAAAU8W51AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAPUExURQAAAAgICAQEBAICAgYGButKD8YAAAAFdFJOUwAKBgQHU1PKwAAAAHxJREFUOMvdlEsKwCAMREO9gAYPUIz3P2NJhEIhmS6CG2f3mICYzxDR1ZaYVF/6c8s07OLQW84u5dwyZutDXFr1HJLWS0iHScC3b2LQskpRg5UqRcNRyrruQhmp6y6jUdbF7+76L+4znhGe76HC94tuH+fGtrzCOZlJYOA+ZrsiUfKf8EEAAAAASUVORK5CYII=') /*dot-tile-background.png*/;
  background-size: 25px 25px;
  background-attachment: fixed;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
